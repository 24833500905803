"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aside-menu + submenu
    // *
    // *

    // Select the menu button, aside element, and menu close button
    const menuBtn = document.querySelector(".js-menu-btn");
    const aside = document.querySelector(".js-menu");
    const menuclose = document.querySelector(".js-menu-close");

    // Toggle the "open" class of the aside element when the menu button is clicked
    if (menuBtn !== null) {
        menuBtn.onclick = function(e) {
            e.preventDefault();
            aside.classList.toggle("open");  
        }
    }

    // Toggle the "open" class of the aside element when the menu close button is clicked
    if (menuclose !== null) {
        menuclose.onclick = function(e) {
            aside.classList.toggle("open");  
        }
    }

    // Toggle the "open" class of the aside element when the "Escape" key is pressed
    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape") {
            event.preventDefault();
            aside.classList.toggle("open");  
        }
    });

    // Toggle the "open" class of the aside element when any element with the class "js-menu-close" is clicked
    document.onclick = function(e){
        if (e.target.classList.contains('js-menu-close')) {
            aside.classList.toggle("open");  
        }
    };

    // Select all elements with the class "has-submenu"
    var hasSubmenu = document.querySelectorAll('.has-submenu');

    // Function to close all submenus except the one provided
    function closeAllSubmenusExcept(exceptSubmenu) {
        for (var i = 0; i < hasSubmenu.length; i++) {
            var submenu = hasSubmenu[i].querySelector('.submenu');
            if (submenu !== exceptSubmenu) {
                hasSubmenu[i].classList.remove('active');
            }
        }
    }

    // Iterate over all elements with the class "has-submenu"
    for (var i = 0; i < hasSubmenu.length; i++) {
        var arrow = hasSubmenu[i].querySelector('.arrow');
        // Add a click event listener to each arrow element
        arrow.addEventListener('click', function (event) {
            event.stopPropagation();
            var menuItem = this.parentElement;
            var submenu = menuItem.querySelector('.submenu');
            
            if (menuItem.classList.contains('active')) {
                menuItem.classList.remove('active');
            } else {
                // Close all submenus except the current one and add the "active" class to the current menu item
                closeAllSubmenusExcept(submenu);
                menuItem.classList.add('active');
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header and sidebar
    // * change header background on scroll
    // *

    // Get the header and sidebar
    const header = document.querySelector(".js-header");

    // Add a scroll event listener to the document to handle the header background and sidebar color
    document.addEventListener('scroll', function(e) {
        if (window.scrollY > 100) {
            header.classList.add('scroll');
        } else {
            header.classList.remove('scroll');
        }
    });
    


    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    if (heroSlider !== null) {
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageSlider
    // *
    // *

    const imageSlider = document.getElementsByClassName('js-image-slider');
    
    if (imageSlider !== null) {
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    const textSlider = document.getElementsByClassName('js-text-slider');
    
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide instagram
    // *
    // *
    
    // Select the instagramSlider
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");

    // Check if imageSlider were found
    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            // splide init
            const splide = new Splide(instagramSlider[i], {
                type            : 'loop',
                rewind          : true,
                autoplay        : true,
                arrows          : false,
                pagination      : false,
                lazyLoad        : 'nearby',
                perPage         : 3,
                perMove         : 1,
                speed           : 1000,
                interval        : 2000,
                gap             : '2rem',
                breakpoints: {
                    768: {
                        perPage: 2,
                        perMove : 1,
                    },
                    576: {
                        perPage : 1,
                        perMove : 1,
                    },
                }
            }).mount();
        }
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageTextSlider
    // *
    // *

    // Get all image-text sliders on the page
    const imageTextSliders = document.querySelectorAll('.js-imagetext-slider');

    // Loop through each image-text slider
    imageTextSliders.forEach(function(imageTextSlider) {
        // Create a new Splide instance for the slider
        const slider = new Splide(imageTextSlider, {
            type: 'slide',
            rewind: true,
            arrows: true,
            pagination: true,
            lazyLoad: 'nearby',
            gap: '3rem'
        });

        // Mount the slider.
        slider.mount();
    });


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        for(let links = document.getElementsByTagName("a"), number = 0; number < links.length; number++) {
            let currentLink = links[number];
            
            // check if link has same hostname. if not add target blank.
            currentLink.getAttribute("href") && currentLink.hostname !== location.hostname && (currentLink.target = "_blank")
        }
    };
    
    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video - play and stop video in viewport
    // *
    // *

    const videos = document.querySelectorAll(".js-video");

    // Create a new IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            // If the video is intersecting the viewport, play it
            if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
                entry.target.play();
            } else {
                // Otherwise, pause it
                entry.target.pause();
            }
        });
    }, { threshold: 0.5 });

    // Loop through all videos and observe them
    videos.forEach((video) => {
        // Check if the video element has the class "js-video"
        if (video.classList.contains("js-video")) {
            observer.observe(video);
        }
    });


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * gsap
    // *
    // *

    gsap.registerPlugin(SplitText, ScrollTrigger, ScrollSmoother, DrawSVGPlugin);

    let smoother = ScrollSmoother.create({
        wrapper: "#smooth-wrapper",
        content: "#smooth-content",
        smooth: 1,
        effects: true,
    });
    
    let elements = document.querySelectorAll(".js-split-text");
    
    elements.forEach((el) => {
        let mySplitText = new SplitText(el, { type: "chars, words" });
        let chars = mySplitText.chars;

        gsap.from(chars, {
            yPercent: 120,
            stagger: function () {
                return Math.random() * 0.4;
        },
        ease: "back.inOut",
        opacity: 0,
        duration: 0.5,
        scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "top 80%",
            toggleActions: "play reset play reset",
            scrub: true,
            markers: false,
            },
        });
    });

    let icons = gsap.utils.toArray('.iconListSvgAnim');
    console.log(icons);
    icons.forEach(function(icon) {
        let animation = gsap.from(icon.querySelectorAll("path"), {duration: 1, stagger: 0.1, drawSVG: 0, paused: false});
        console.log(animation);
        ScrollTrigger.create({
            trigger: icon,
            start: "top 80%",
            onEnter: function() {
                console.log("onEnter");
                animation.play();
            },
            onLeaveBack: function() {
                console.log("onLeaveBack");
                animation.reverse();
            },
            onEnterBack: function() {
                console.log("onEnterBack");
                animation.play();
            },
            onLeave: function() {
                console.log("onLeave");
                animation.reverse();
            }
        });
    });

    

    // function animateFrom(elem, direction) {
    //     direction = direction || 1;
    //     var x = 0,
    //         y = direction * 100;
    //     if(elem.classList.contains("gs_reveal_fromLeft")) {
    //       x = -200;
    //       y = 0;
    //     } else if (elem.classList.contains("gs_reveal_fromRight")) {
    //       x = 200;
    //       y = 0;
    //     }
    //     elem.style.transform = "translate(" + x + "px, " + y + "px)";
    //     elem.style.opacity = "0";
    //     gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
    //       duration: 1.25, 
    //       x: 0,
    //       y: 0, 
    //       autoAlpha: 1, 
    //       ease: "expo", 
    //       overwrite: "auto"
    //     });
    //   }
      
    //   function hide(elem) {
    //     gsap.set(elem, {autoAlpha: 0});
    //   }
      
      
        
    //     gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
    //       hide(elem); // assure that the element is hidden when scrolled into view
          
    //       ScrollTrigger.create({
    //         trigger: elem,
    //         markers: true,
    //         onEnter: function() { animateFrom(elem) }, 
    //         onEnterBack: function() { animateFrom(elem, -1) },
    //         onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
    //       });
    //     });
 





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTo
    // *
    // *

    // Select all elements with the class "js-scrollTo"
    const anchors = document.querySelectorAll(".js-scrollTo");

    // Iterate over each anchor element
    for (const anchor of anchors) {
        // Add a click event listener to each anchor
        anchor.addEventListener("click", clickHandler);
    }

    // Function to handle the click event on anchor elements
    function clickHandler(e) {
        e.preventDefault();

        // Get the href attribute value and split it at the "#" symbol
        let href = this.getAttribute("href").split("#");
        
        // Create a new anchor value by adding "my" before the original anchor value
        let newAnchor = "#my" + href[1];
        
        // Get the offsetTop position of the element with the new anchor value
        let offsetTop = document.querySelector(newAnchor).offsetTop;

        // Scroll to the specified offsetTop position with smooth behavior
        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    // Add a "load" event listener to the window
    window.addEventListener('load', function () {
        // Get the hash value from the URL
        let getHash = window.location.hash;

        // Check if an element with the ID "my" + hash value exists
        let elementExists = document.getElementById("my" + getHash.slice(1));
    
        // If the hash value exists and the corresponding element exists
        if (getHash !== null && elementExists !== null) {
            // Create the name of the element by adding "my" before the hash value
            let name = "#my" + getHash.slice(1);
            
            // Get the offsetTop position of the element with the specified name
            let offsetTop = document.querySelector(name).offsetTop;
            // Scroll to the specified offsetTop position with smooth behavior
            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }, false);
});